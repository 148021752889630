import { isRef, ref } from 'vue'

import type { Composition } from '~/types/composable'

/**
 * Provides a proxy that defines refs on demand, such that:
 * - You do not have to initialize refs prior to access or value assignment.
 * - Once a ref is initialized it cannot be overridden.
 */
export const refProxy = <TComposition extends Composition>(
  composition: TComposition
): TComposition & Composition =>
  new Proxy(composition, {
    get: (target, propertyKey, receiver) => {
      if (!Reflect.has(target, propertyKey)) {
        Reflect.set(target, propertyKey, ref(undefined), receiver)
      }
      return Reflect.get(target, propertyKey, receiver)
    },
    set: (target, propertyKey, value, receiver) => {
      if (isRef(Reflect.get(target, propertyKey, receiver))) {
        const keyName =
          typeof propertyKey === 'symbol' ? propertyKey.toString() : propertyKey
        throw new TypeError(
          `Ref for ${keyName} already exists in the composition.`
        )
      }
      return Reflect.set(target, propertyKey, value, receiver)
    },
  })
