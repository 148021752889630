<script lang="ts" setup>
/**
 * This component handles a specific subset of fatal error cases, including
 * cases where NuxtError is deliberately thrown with the fatal flag via the showErrorPage() util.
 *
 * @see: https://nuxt.com/docs/getting-started/error-handling#rendering-an-error-page
 * @see: https://nuxt.com/docs/getting-started/error-handling#createerror
 */
import { SkyButton, SkyImage } from '@groveco/skylight'
import type { NuxtError } from '#app'
import { HandlingLevel, SeverityLevel } from '@/plugins/sentry.client'

const props = defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true,
  },
})

const { $sentry } = useNuxtApp()
onMounted(() => {
  $sentry.captureException(props.error, {
    tags: {
      level: SeverityLevel.FATAL,
      handling: HandlingLevel.WITH_MSG,
    },
  })
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div>
    <HeaderLogoOnly />
    <main class="Error">
      <SkyImage
        src="https://images.grove.co/upload/f_auto,q_auto:low,fl_progressive,w_672,ar_4:3,c_fill,g_auto/v1591749331/global/Line%20Illustrations/no-image-available-4x3.jpg"
        alt="Line illustration of a hand holding a leaf"
      />
      <div class="Error_Content">
        <h3>{{ error?.statusMessage }}</h3>
        <p>
          The page you requested cannot be displayed at this time. It may be
          temporarily unavailable, the link you clicked may have expired, or you
          may not have permission to view this page.
        </p>
        <SkyButton text dark right-icon="arrow" @click="handleError">
          Return Home
        </SkyButton>
      </div>
    </main>
    <TheFooter hide-sections />
  </div>
</template>

<style lang="scss">
.Error {
  @include container-small;

  align-content: space-evenly;
  justify-items: center;
  min-height: 80vh;

  @include for-medium-up {
    --col: min(var(--container-max) / 2, 100% - var(--container-margin) * 2);
    grid-template-columns: 1fr var(--col) var(--col) 1fr;
    align-items: center;

    &_Content {
      grid-column: 3;
    }
  }
}
</style>
