const noop = () => {}

/**
 * A helper function to create a development only logger instance.
 *
 * @param name The name with which to label log lines
 * @param color The hex code with which to differentiate the plugin logging
 * @param env The env for which the logger is being created
 * @returns A logger with which to log in development but not prod.
 */
export const buildDevOnlyLogger = (options: {
  name: string
  color: string
  env: string
  debug?: boolean
}) => {
  /**
   * Helper function to decorate a logging method with extra functionality
   * @param consoleMethod The target method to decorate
   * @returns The decorated function
   */
  const createMethodFor = (
    consoleMethod: Console['info'] | Console['warn'] | Console['error']
  ) => {
    if (options.env === 'production' || options.debug === false) {
      return noop
    }
    return (...args: Array<any>) =>
      consoleMethod(
        `%c dev-only %c %c ${options.name} `,
        `
          color: black;
          background-color: rgba(255, 255, 255, 0.3);
          padding: 1px;
          border: .5px solid black;
          border-radius: 4px;
        `,
        `background: inherit;`,
        `
          color: #ffffff;
          background-color: ${options.color};
          font-weight: bold;
          padding: 1px;
          border-radius: 4px;
        `,
        ...args
      )
  }

  /* eslint-disable no-console */
  return {
    info: createMethodFor(console.info),
    warn: createMethodFor(console.warn),
    error: createMethodFor(console.error),
  }
  /* eslint-enable no-console */
}

/**
 * A helper function to create a development only plugin logger.
 *
 * @param name The name of the plugin with which to label log lines
 * @param color The hex code with which to differentiate the plugin logging
 * @param env The env for which the logger is being created
 * @returns A plugin logger with which to log in development but not prod.
 */
export const buildPluginLogger = (options: {
  name: string
  color: string
  env: string
  debug?: boolean
}) =>
  buildDevOnlyLogger({
    ...options,
    name: `plugins/${options.name}`,
  })
