const NAME = 'info-on-app-load'

/**
 * This plugin simply emits some metadata to the console if the environment is
 * nonprod.
 */
export default defineNuxtPlugin({
  name: NAME,
  setup(_) {
    const config = useRuntimeConfig().public
    if (!config.nonProd || !config.buildInfo?.gitRefName) {
      // Early exit to avoid unnecessary evaluations (logging done with `logger`
      // will be silenced in prod, but we shouldn't attempt to log anyhow.)
      return
    }

    const logger = buildPluginLogger({
      name: NAME,
      color: '#033b4c',
      env: config.env,
    })

    logger.info(
      `

+---+---+---+---+---+   +---+---+---+
| g | r | o | v | e |   | w | e | b |   nuxt app
+---+---+---+---+---+   +---+---+---+

- Landing route: ${window.location}
- App built from grove-web:${config.buildInfo.gitRefName}
- Prerender backed by grove-core:${config.buildInfo.groveCoreRefName.replace(
        '.testing.gext.co',
        ''
      )}
- Runtime config as follows
`,
      toRaw(config),
      '\n\n'
    )
  },
})
