/**
 * Defines constant routes that might need to be referenced by many components.
 */

export interface Route {
  target?: string
  text?: string
  to: string
  rel?: string
}

export interface RouteWithCopy extends Route {
  text: string
  image?: string
}

export const ROUTES = {
  accountSettings: {
    to: '/settings/account',
  },
  about: {
    text: 'Our Story',
    to: '/g/about/',
  },
  carbonDisclosures: {
    text: 'Carbon Disclosures',
    to: '/g/carbondisclosures',
  },
  californiaSupplyChain: {
    text: 'CA Supply Chain Act',
    to: '/ca-supply-chain-act/',
  },
  careers: {
    text: "Careers (We're hiring!)",
    to: '/careers/',
  },
  cartRoute: {
    text: 'Your Cart',
    to: '/pantry/',
  },
  contactUs: {
    text: 'Contact Us',
    target: '_blank',
    to: 'https://community.grove.co/hc/en-us/requests/new',
  },
  frequentlyAsked: {
    text: 'FAQ',
    target: '_blank',
    to: 'https://community.grove.co/hc/en-us/categories/200113215-FAQ/',
    rel: 'noopener',
  },
  groveStandard: {
    text: 'A Higher Standard',
    to: '/g/the-grove-standard/',
  },
  impactRoute: {
    to: '/impact/',
    text: 'Your Impact',
  },
  inactiveRoute: {
    to: '/reactivate/',
  },
  loginRoute: {
    to: '/pantry-login/',
  },
  logoutRoute: {
    text: 'Log Out',
    to: '/logout/',
  },
  memberHome: {
    to: '/member/home/',
  },
  membershipSettings: {
    text: 'VIP Membership',
    to: '/settings/membership',
  },
  policies: {
    text: 'Policies & Disclosures',
    to: '/policies/',
  },
  privacy: {
    text: 'Privacy Policy',
    to: '/privacy/',
  },
  passwordReset: {
    to: '/accounts/password/reset/',
  },
  subscriptions: {
    to: '/settings/subscriptions/',
  },
  terms: {
    text: 'Terms of Use',
    to: '/terms/',
  },
  vipHub: {
    to: '/vip-hub/',
  },
  visitorRoute: {
    to: '/',
  },
  wellnessRoute: {
    text: 'Meet Grove Wellness',
    to: '/g/wellness',
  },
}
