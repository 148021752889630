import { offer as offerService } from '@groveco/http-services'
import { createSharedComposable } from '~/composables/utils/createSharedComposable'
import { refProxy } from '~/composables/utils/refProxy'

/**
 * Use offer composable.
 * Meant to successfully fetch and apply offer details only once on page load with current code design.
 * Use offer details throughout the app while first checking for the isOfferApplied value.
 */

const useOffer = () => {
  const offer = refProxy({
    applyOfferFailed: ref(false),
    applyToExisting: ref(false),
    applyToVisitors: ref(false),
    fetchOfferFailed: ref(false),
    isActive: ref(false),
    isBusy: ref(false),
    isOfferApplied: ref(false),
    triedToFetchOfferAgain: ref(false),
  })

  const { offerId: sessionOfferId, id: customerId, checkedOut } = useCustomer()
  const route = useRoute()

  const { client } = useApiClient()

  const fetchOffer = async (offerParam) => {
    if (!offer.isBusy.value) {
      offer.isBusy.value = true

      if (offer.fetchOfferFailed.value) {
        offer.triedToFetchOfferAgain.value = true
      }

      try {
        const offerFetched = await offerService.fetchOffer(client, offerParam)

        for (const [key, value] of Object.entries(offerFetched)) {
          offer[key].value = value
        }
      } catch {
        offer.fetchOfferFailed.value = true
      }

      offer.isBusy.value = false
    }
  }

  const applyOffer = async (offerId) => {
    if (!offer.isBusy.value) {
      offer.isBusy.value = true

      try {
        await offerService.applyOffer(client, customerId.value, offerId)
        if (offerId === offer.id.value) {
          offer.isOfferApplied.value = true
        }
      } catch {
        offer.applyOfferFailed.value = true
      }
      offer.isBusy.value = false
    }
  }

  // fetch the offer if we don't have an offer id and offer fetch failure has not occurred OR offer fetch failure has occurred
  // but we haven't yet tried again
  watchEffect(() => {
    if (
      route.query &&
      sessionOfferId.value &&
      !offer.id.value &&
      (!offer.fetchOfferFailed.value ||
        (offer.fetchOfferFailed.value && !offer.triedToFetchOfferAgain.value))
    ) {
      const offerParam =
        (!offer.fetchOfferFailed.value
          ? route.query.offer
          : sessionOfferId.value) || sessionOfferId.value
      fetchOffer(offerParam)
    }
  })

  // if apply offer failure has not occurred -
  // 1. Apply the offer id if the offer is active and eligible for the current customer and the offer id is different than the session offer id, otherwise
  // 2. Fetch the session offer details

  watchEffect(() => {
    if (
      offer.id.value &&
      sessionOfferId.value &&
      !offer.isOfferApplied.value &&
      !offer.applyOfferFailed.value
    ) {
      if (offer.id.value.toString() !== sessionOfferId.value.toString()) {
        const isEligibleOffer =
          (offer.applyToExisting.value && checkedOut.value) ||
          (offer.applyToVisitors.value && !checkedOut.value)

        if (offer.isActive.value && isEligibleOffer) {
          applyOffer(offer.id.value)
        } else {
          fetchOffer(sessionOfferId.value)
        }
      } else {
        offer.isOfferApplied.value = true
      }
    }
  })

  return offer
}

export default createSharedComposable(useOffer)
