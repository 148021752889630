/**
 * Defines constant logo URLs that might need to be referenced by many components.
 */
export const LOGOS = {
  beyondPlastic:
    'https://images.grove.co/upload/v1701812394/global/Logos/BeyondPlastic/BeyondPlastic-Logo.svg',
  wordmark: {
    trademark:
      'https://images.grove.co/upload/v1710801415/global/Logos/Grove-wordmark.svg',
    registered:
      'https://images.grove.co/upload/v1710801411/global/Logos/Grove-Collaborative-wordmark.svg',
    cairn:
      'https://images.grove.co/upload/v1710801411/global/Logos/Grove-logomark.svg',
  },
  icon: {
    light:
      'https://images.grove.co/upload/v1554326506/global/Icons/svg-icons/grove-symbol-white.svg',
    recover:
      'https://images.grove.co/upload/v1716403461/global/Icons/Impact/ic-recover.svg',
    avoid:
      'https://images.grove.co/upload/v1716403461/global/Icons/Impact/ic-avoid.svg',
  },
}
