import { createSharedComposable } from '~/composables/utils/createSharedComposable'

import { MOBILE_APP_IMAGES } from '~/constants/mobile'

const useMobile = () => {
  const { id, isMobileAppUser } = useCustomer()

  // Default values for server-side rendering
  const isMobile = ref(false)
  const isIOS = ref(false)
  const isAndroid = ref(false)

  const appBadge = computed(() => {
    // No badge needed if the user already downloaded the app
    if (!id.value || isMobileAppUser.value) {
      return null
    }

    if (isIOS.value) {
      return MOBILE_APP_IMAGES.iosImage
    }

    if (isAndroid.value) {
      return MOBILE_APP_IMAGES.androidImage
    }

    return null
  })

  onMounted(() => {
    const userAgentString = navigator?.userAgent?.toLowerCase() || ''
    isIOS.value =
      userAgentString.includes('iphone') || userAgentString.includes('ipad')
    isAndroid.value = !isIOS.value && userAgentString.includes('android')
    isMobile.value = isIOS.value || isAndroid.value
  })

  return {
    appBadge,
    isAndroid,
    isIOS,
    isMobile,
  }
}

export default createSharedComposable(useMobile)
