/**
 * Custom error namespace for errors that occur during value hashing.
 */
export class HashError extends Error {
  /**
   * Construct a `HashError` error instance.
   *
   * @param {string} message The error message.
   */
  constructor(message) {
    super(message)
    this.name = 'HashError'
  }
}

/**
 * Ensure `window.crypto` is defined.
 *
 * This occurs when the current browser does not support cryptography operations (IE11).
 * During testing you should probably be mocking the exported `hash` function
 *
 * @throws {HashError} When `window.crypto` is undefined.
 * @returns {void}
 */
function _ensureCrypto() {
  if (!window.crypto)
    throw new HashError('window.crypto is not supported in this browser')
}

/**
 * Produce a matching uint8 byte array for a given string value.
 *
 * @param {string} value The value to encode as a byte array.
 * @returns {Uint8Array} The string value encoded as a uint8 byte array.
 */
function _buildByteArray(value) {
  return new TextEncoder().encode(value)
}

/**
 * Translate a given hash ArrayBuffer to hex.
 *
 * @param {ArrayBuffer} hashBuffer The hash buffer produced by `window.crypto.digest`.
 * @returns {string} The hexdigest for the given hash buffer.
 */
function _buildHexDigest(hashBuffer) {
  return Array.from(new Uint8Array(hashBuffer))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
}

/**
 * Get a hash hexdigest for a given value.
 *
 * @param {string} algorithm An algorithm identifier supported by `window.crypto`.
 * @param {string} value The value to hash with the given algorithm.
 * @returns {Promise<string>} The hexdigest of the given value.
 */
export async function hash(algorithm, value) {
  _ensureCrypto()
  return _buildHexDigest(
    await crypto.subtle.digest(algorithm, _buildByteArray(value))
  )
}

/**
 * Get the sha256 hexdigest of a given value.
 *
 * @param {string} value The value to hash with sha256.
 * @returns {Promise<string>} The sha256 hexdigest of the given value.
 */
export async function sha256(value) {
  return await hash('SHA-256', value)
}
