import * as Sentry from '@sentry/vue'
import type { ScopeContext } from '@sentry/types'

const NAME = 'sentry'
const DSN =
  'https://d0ce7ac1e6ef4f36868e77c625140f2b@o44115.ingest.sentry.io/5900884'

/**
 * Different handling statuses to describe errors logged to Sentry via
 * $sentry.captureException().
 *
 * Note that all exceptions logged via $sentry.captureException will come in
 * as `handled: yes` or `true` per Sentry's criteria. HandlingStatus gives us
 * more insight into how or whether we truly handled the error.
 */
export const enum HandlingLevel {
  /** If the error was caught and handled both gracefully and silently */
  SILENT = 'silent',
  /** If the error was caught and handled with an error message to the user */
  WITH_MSG = 'with-message',
  /** If the error caught with no mitigation of fallout; i.e. swallowed. */
  CAUGHT_ONLY = 'caught-only',
}

/**
 * A subset of Sentry.SeverityLevel's to be applied to errors logged via
 * $sentry.captureException() in accordance with the following criteria.
 *
 * Note: log, info or debug levels are excluded as purely informational
 * logging should be logged as messages ($sentry.captureMessage()) as opposed
 * to exceptions ($sentry.captureException()).
 */
export const enum SeverityLevel {
  /** Routine error where high volume is unexpected and a cause for concern. */
  WARNING = 'warning',
  /**
   * Unexpected error where the scope of failure is limited to an individual
   * operation or transaction.
   */
  ERROR = 'error',
  /** Unexpected failure which breaks entire page or app functionality. */
  FATAL = 'fatal',
}

/**
 * Contextual information required as tags on exceptions that we catch and log
 * via $sentry.captureException.
 *
 * Note that we require the following as tags rather than structured contexts.
 * This is because tags are searchable whereas custom contexts are not.
 * @see: https://docs.sentry.io/product/sentry-basics/search/searchable-properties/events/#searchable-properties
 */
interface EnforcedTagging {
  /**
   * The severity of the error.
   */
  level: SeverityLevel
  /**
   * How the error was handled, if at all.
   */
  handling: HandlingLevel
}

/**
 * This custom plugin for Sentry aims to provide a Sentry integration to the
 * Nuxt app with which we can report errors to Sentry. It is a stop-gap solution
 * until the nuxt community Sentry plugin supports Nuxt 3.
 *
 * There may be implementation gaps from the Sentry plugin we historically
 * used with Nuxt 2. Namely, we do not lazy load Sentry.
 */
export default defineNuxtPlugin({
  name: NAME,
  setup(nuxtApp) {
    const { vueApp } = nuxtApp
    const config = useRuntimeConfig().public.sentry
    const env = useRuntimeConfig().public.env
    const logger = buildPluginLogger({
      name: NAME,
      color: '#660080',
      env,
      debug: config.debug,
    })

    let dsn = DSN
    if (!config.enabled) {
      logger.info('Capture sends are disabled in plugin configuration', config)
      dsn = ''
    }

    Sentry.init({
      app: [vueApp],
      dsn,
      debug: config.debug,
      environment: env,
      beforeSend(event, hint) {
        logger.info(`Captured event: ${hint.originalException}`, {
          event,
          hint,
        })
        // Continue sending to Sentry
        return event
      },
    })

    // Global event tagging:
    // Decorate all events logged within this scope with the nuxt3 tag.
    Sentry.setTag('project', 'nuxt3')

    vueApp.mixin(
      Sentry.createTracingMixins({
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update'],
      })
    )
    Sentry.attachErrorHandler(vueApp, {
      logErrors: true,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    })

    /**
     * This decorator method wraps the original Sentry captureException API
     * method with the following modifications:
     * 1. It enforces that all captureException calls specify the level of the
     *    error.
     * 2. It enforces that all captureException calls specify the handling of
     *    the error.
     *
     * @param e The error to log to Sentry
     * @param captureContext Context for error, with required and optional tags
     * @returns The generated event id
     */
    const captureException = (
      e: Error | string,
      captureContext: Partial<ScopeContext> & { tags: EnforcedTagging }
    ): string => {
      const { level, ...remainingTags } = captureContext.tags
      return Sentry.captureException(e, {
        ...captureContext,
        // Level is a searchable context property native to Sentry, so we
        // provide it to Sentry at the context level rather than as a tag.
        level,
        tags: remainingTags,
      })
    }

    return {
      provide: {
        sentry: {
          setContext: Sentry.setContext,
          setUser: Sentry.setUser,
          setTag: Sentry.setTag,
          addBreadcrumb: Sentry.addBreadcrumb,
          captureException,
          captureMessage: Sentry.captureMessage,
        },
      },
    }
  },
})
