import { isRef, watch } from 'vue'

import type { MaybeRef, Ref } from 'vue'

interface RefOrResolver extends MaybeRef {
  ref: Ref
  condition?: (val: any) => boolean
  timeout?: number
}

const conditionDefault = (val: any) => val !== undefined

/**
 * Composables can return refs that are lazy loaded.
 * This is fine for Vue components but there are times when
 * we need to wait for the value to resolve before we can make
 * a routing decision or send an analytics event.
 *
 * @example: Wait until customer has loaded.
 * const { id } = useCustomer();
 * await resolveRef(id);
 *
 * @example: Wait until a condition is true within a set period of time.
 * const { example } = useExample();
 * await resolveRef({
 *   condition: (val) => val === 'success',
 *   ref: example,
 *   timeout: 6000,
 * })
 *
 */
export function resolveRef(obj: RefOrResolver | Ref): Promise<Ref> {
  const timeout = obj?.timeout ?? 15000
  const ref = obj?.ref ?? obj

  if (!isRef(ref)) {
    throw new TypeError('resolveRef requires a ref.')
  }

  const condition =
    typeof obj.condition === 'function' ? obj.condition : conditionDefault

  // Resolve early if condition matches
  if (condition(ref.value)) return Promise.resolve(ref)

  return new Promise((resolve, reject) => {
    let timer: ReturnType<typeof setTimeout> | undefined
    const watcher = watch(ref, () => {
      if (condition(ref.value)) {
        // Resolve the ref
        resolve(ref)
        // End the watcher
        watcher()
        // End the timer
        if (timer) {
          clearTimeout(timer)
        }
      }
    })
    if (timeout > 0) {
      timer = setTimeout(() => {
        reject(new Error('resolveRef condition timeout.'))
        // End the watcher
        watcher()
      }, timeout)
    }
  })
}
