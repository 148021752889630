import { ref } from 'vue'
import type { MenuItem } from '@groveco/http-services'

import { createSharedComposable } from '~/composables/utils/createSharedComposable'

/**
 * Produces a shared ref for navigational menu items
 *
 * Note: This composable does not automatically load menuItems
 * on instantiation. This is because it is written to be used
 * in conjunction with useAsyncData() such that the fetch only
 * happens once, server-side.
 *
 * @see: https://nuxt.com/docs/api/composables/use-async-data
 * @see: https://content.nuxt.com/composables/query-content#important-%EF%B8%8F
 */
export default createSharedComposable(() => {
  const { hasPlacedOrder } = useCustomer()
  const menuItems = ref<Array<MenuItem>>([])

  const fetchMenuItems = async () => {
    const menu = await queryContent('menu')
      .where({ menuItems: { $exists: true } })
      .findOne()
    return menu.menuItems
  }

  const setMenuItems = (value: Array<MenuItem>) => {
    menuItems.value = value
  }

  const educationItems = computed<MenuItem[]>(() => [
    {
      id: 'mission',
      children: [
        {
          id: 'our-mission',
          children: [],
          href: '/g/our-mission',
          text: 'Our Mission',
        },
        {
          id: 'beyond-plastic',
          children: [],
          href: '/beyondplastic/',
          text: 'Beyond Plastic™',
        },
        {
          id: 'environmental-impact',
          children: [],
          href: '/g/sustainability/',
          text: 'Environmental Impact',
        },
      ],
      href: '/g/our-mission',
      text: 'Mission',
    },
    {
      id: 'standards',
      children: [
        {
          id: 'higher-standard',
          children: [],
          href: '/g/the-grove-standard/',
          text: 'A Higher Standard',
        },
        {
          id: 'no-way-ingredients',
          children: [],
          href: '/g/no-way-ingredients/',
          text: 'No Way Ingredients',
        },
      ],
      href: '/g/the-grove-standard/',
      text: 'Standards',
    },
    {
      id: 'how-it-works',
      children: [
        {
          id: 'sns',
          children: [],
          href: hasPlacedOrder.value
            ? '/g/subscribe-and-save-member/'
            : '/g/subscribe-and-save/',
          text: 'Subscribe and Save',
        },
        {
          id: 'vip',
          children: [],
          href: '/g/vip/',
          text: 'VIP Membership',
        },
        {
          id: 'free-returns',
          children: [],
          href: '/g/shipping-returns/',
          text: 'Free Returns',
        },
      ],
      href: '/how-grove-works/',
      text: 'How It Works',
    },
  ])

  return {
    educationItems,
    /**
     * Ref for menu items. Will only contain the actual data after fetch and
     * set calls have been made. */
    menuItems,
    /** Method to fetch menu items. */
    fetchMenuItems,
    /** Method to set menu items. */
    setMenuItems,
  }
})
