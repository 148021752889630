import type { ServiceClient } from '@groveco/http-services'
import createHttpClient from '@groveco/http-services'
import { createSharedComposable } from '~/composables/utils/createSharedComposable'

const apiClient = () => {
  const baseURL = '/api'
  const headers = {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
  }

  return {
    client: createHttpClient({
      baseURL,
      headers,
    }),
  }
}
export default createSharedComposable(apiClient) as () => {
  client: ServiceClient
}
