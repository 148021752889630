/**
 * Convert a string to camelCase
 *
 * @example
 *
 * camelCase('one-two')
 *  => 'oneTwo'
 *
 * camelCase('ONE_TWO')
 *  => 'oneTwo'
 *
 * camelCase('One Two')
 *  => 'oneTwo'
 */
export const camelCase = (string = '') => {
  return string
    .toLowerCase()
    .replace(/^([,._:-\s]+)|([,._:-\s]+)$/g, () => '')
    .replace(/([,._:-\s])(\w)/g, (match) => match[1].toUpperCase())
}

/**
 * Converts the given string from camelCase to snake_case
 */
export const camelToSnake = (string = '') => {
  return (
    string
      .replace(/[A-Z]/g, (char) => `_${char.toLowerCase()}`)
      // In case of PascalCase
      .replace(/^_/, '')
  )
}
