<script setup lang="ts">
import { SkyContext } from '@groveco/skylight'
import type { MenuItem } from '@groveco/http-services'

import { NuxtLink } from '#components'
import useCustomer from '~/composables/useCustomer'
import { sha256 } from '~/composables/utils/hash'

/**
 * We use the provide helper method to ensure that NuxtLink is used as
 * default for all instances of `SkyLink`.
 *
 * NuxtLink intelligently determines whether the link is internal or external and
 * renders it accordingly.
 *
 * @see: https://nuxt.com/docs/api/components/nuxt-link
 */
provide(SkyContext.RoutingTag, NuxtLink)

// Fetch menu items with useAsyncData to ensure that retrieval happens on
// server-side only (value gets cached for client-side access).
const { fetchMenuItems, setMenuItems } = useMenuItems()
const { data } = await useAsyncData<Array<MenuItem>>(
  'menuItems',
  fetchMenuItems
)
if (data.value) {
  setMenuItems(data.value)
}

const route = useRoute()
const { $events } = useNuxtApp()
const {
  email,
  fullName,
  id: customerId,
  isActive,
  hasPlacedOrder,
  hasUsablePassword,
  plan,
  isAutoship,
  membershipId,
} = useCustomer()
const { code: offerCode, id: offerId, isBusy: isOfferBusy } = useOffer()

/**
 * State that tracks the last tracked location.
 */
const lastTrackedLocation = ref({
  path: '',
  query: {},
  origin: '',
})

/**
 * Helper method to track a page view event.
 */
const trackPageView = () => {
  const route = useRoute()
  const currLocation = $events.contexts.page.buildPageLocationContext()
  currLocation.path = `${currLocation.path}${route.hash}`
  $events.emit(
    ...$events.pageView({
      prevLocation: lastTrackedLocation.value,
      location: currLocation,
      project: { name: 'grove-web' },
    })
  )
  lastTrackedLocation.value = currLocation
}

/**
 * Helper method to track a customer update event
 */
const trackCustomerUpdate = async () => {
  if (!customerId.value || isOfferBusy.value) return

  $events.emit(
    ...$events.customerUpdate({
      customer: {
        id: String(customerId.value),
        isActive: isActive.value,
        isVisitor: !hasPlacedOrder.value,
        isAutoship: isAutoship.value,
        hasMembership: Boolean(membershipId.value),
        hasUsablePassword: hasUsablePassword.value,
      },
      project: {
        name: 'grove-web',
      },
    })
  )

  const getCustomerAnalyticsName = () => {
    if (fullName.value) {
      return fullName.value
    } else if (customerId.value) {
      return `Customer ${customerId.value}`
    } else {
      return 'Unknown Customer'
    }
  }

  const hashedEmail = email.value ? await sha256(email.value) : null

  const getCustomerDetails = () => ({
    id: customerId.value,
    name: getCustomerAnalyticsName(),
    hashedEmail,
    email: email.value,
    isActive: isActive.value,
    isVisitor: !hasPlacedOrder.value,
    offerCode: offerCode.value,
    isSno: plan?.value === 10,
    isVip: hasPlacedOrder.value && membershipId.value,
    // we don't have a great way of getting this right now and aren't sending this from the SPA client either
    segmentIds: [],
    offerCodeId: offerId.value,
  })

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'customerChange',
    customer: getCustomerDetails(),
    userId: customerId.value,
    isStaticPageEvent: true,
  })
}

onMounted(() => {
  watchEffect(trackCustomerUpdate)
  watch(() => route.fullPath, trackPageView, { immediate: true })
})

useHead({
  titleTemplate: (titleChunk) => {
    const endTitle = '| Grove Collaborative'
    return titleChunk
      ? `${titleChunk} ${endTitle}`
      : `Natural Household and Personal Care Products ${endTitle}`
  },
  script: [
    {
      type: 'application/ld+json',
      children: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Grove Collaborative',
        legalName: 'Grove Collaborative, Inc.',
        url: 'https://www.grove.co',
        logo: 'https://images.grove.co/upload/v1549478001/global/Logos/Regular/Grove_Collaborative_Logo.svg',
        foundingDate: '2012',
        founders: [
          {
            '@type': 'Person',
            name: 'Stuart Landesberg',
          },
          {
            '@type': 'Person',
            name: 'Jordan Savage',
          },
          {
            '@type': 'Person',
            name: 'Chris Clark',
          },
        ],
        address: {
          '@type': 'PostalAddress',
          streetAddress: '1301 Sansome St',
          addressLocality: 'San Francisco',
          addressRegion: 'CA',
          postalCode: '94111',
          addressCountry: 'USA',
        },
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'customer support',
          telephone: '[+1-844-476-8375]',
          email: 'community@grove.co',
        },
        sameAs: [
          'https://www.instagram.com/grovecollaborative/',
          'https://twitter.com/grovecollab',
          'https://www.facebook.com/GroveCollab/',
          'https://www.pinterest.com/grovecollab/',
          'https://www.youtube.com/channel/UCymHP9mlJIV5yfcuT70e_5w',
          'https://www.glassdoor.com/Overview/Working-at-Grove-Collaborative-EI_IE1359221.11,30.htm',
        ],
      },
    },
  ],
})
</script>

<template>
  <NuxtPage />
</template>
