import { isRef } from 'vue'
import type {
  Customer,
  CustomerMutation as EditableCustomerFields,
} from '@groveco/http-services'
import {
  customer as CustomerService,
  auth as AuthService,
  errors,
} from '@groveco/http-services'

import { useNuxtApp } from '#app'
import { HandlingLevel, SeverityLevel } from '@/plugins/sentry.client'

import { createSharedComposable } from '~/composables/utils/createSharedComposable'
import { refProxy } from '~/composables/utils/refProxy'
import useApiClient from '~/composables/useApiClient'

import { camelCase } from '~/utils/string'

import type { CompositionFrom } from '~/types/composable'

type FieldError = <T extends string>(
  key: T,
  value: string | Array<T>
) => [T, string | Array<T>]
type ErrorNames =
  | 'LOGIN_FAILED'
  | 'UPDATE_FAILED'
  | 'FETCH_FAILED'
  | 'UNEXPECTED_ERROR'

export class CustomerError extends Error {
  name: ErrorNames
  context?: any
  constructor({
    name,
    message,
    cause,
    context,
  }: {
    name: ErrorNames
    message: string
    cause?: Error
    context?: any
  }) {
    super()
    this.name = name
    this.message = message
    this.cause = cause
    this.context = context
  }
}

const parseFieldErrors = (fieldErrors: FieldError) => {
  const errorsByField = Object.fromEntries(
    Object.entries(fieldErrors).map(([key, value]) => [
      key.includes('_') ? camelCase(key) : key,
      Array.isArray(value) ? value.flat().join(' ') : value,
    ])
  )
  return errorsByField
}

export interface UseCustomer extends CompositionFrom<Customer> {
  updateCustomer: (partial: EditableCustomerFields) => Promise<void>
  login: (email: string, password: string) => Promise<void>
}

const updateCustomerRefs = (
  customer: UseCustomer,
  customerResponse: Readonly<Customer>
): void => {
  for (const [key, value] of Object.entries(customerResponse)) {
    const maybeRefOrGetter = customer[key as keyof UseCustomer]
    if (isRef(maybeRefOrGetter)) {
      maybeRefOrGetter.value = value
    }
  }
}

const customer = () => {
  const { client } = useApiClient()
  const { $sentry } = useNuxtApp()

  const customerRefs = refProxy({}) as UseCustomer

  /**
   * Updates a customer
   */
  customerRefs.updateCustomer = async (partial) => {
    try {
      const customerData = { id: customerRefs.id.value } as Customer

      const result: Readonly<Customer> = await CustomerService.updateCustomer(
        client,
        customerData,
        partial
      )
      updateCustomerRefs(customerRefs, result)
    } catch (err) {
      if (err instanceof errors.RequestFailed) {
        const { fieldErrors, response: httpResponseError } = err
        const errors = fieldErrors ?? httpResponseError?.data?.errors
        throw new CustomerError({
          name: 'UPDATE_FAILED',
          message: 'Update failed',
          cause: err,
          context: parseFieldErrors(errors),
        })
      } else {
        throw new CustomerError({
          name: 'UNEXPECTED_ERROR',
          message: 'Update failed',
        })
      }
    }
  }

  /**
   * Logs in a customer
   */
  customerRefs.login = async (email, password) => {
    try {
      const result: Readonly<Customer> = await AuthService.login(
        client,
        email,
        password
      )
      updateCustomerRefs(customerRefs, result)
    } catch (err) {
      if (err instanceof errors.RequestFailed) {
        const { data: responseError } = err.response
        const errorDetail = Array.isArray(responseError)
          ? responseError[0]
          : responseError
        throw new CustomerError({
          name: 'LOGIN_FAILED',
          message: errorDetail.detail ?? errorDetail.title ?? 'Login failed',
          cause: err,
          context: errorDetail,
        })
      } else {
        throw new CustomerError({
          name: 'UNEXPECTED_ERROR',
          message: 'Login failed',
          cause: err as Error,
        })
      }
    }
  }

  /**
   * Fetches the customer
   */
  const fetchCustomer = async (): Promise<void> => {
    try {
      const result: Readonly<Customer> =
        await CustomerService.fetchCurrentCustomer(client)
      updateCustomerRefs(customerRefs, result)
    } catch (err) {
      if (err instanceof errors.RequestFailed) {
        const { data: responseError } = err.response
        const errorDetail = Array.isArray(responseError)
          ? responseError[0]
          : responseError
        throw new CustomerError({
          name: 'FETCH_FAILED',
          message: errorDetail?.detail ?? errorDetail?.title ?? errorDetail,
          cause: err,
          context: errorDetail,
        })
      } else {
        throw new CustomerError({
          name: 'UNEXPECTED_ERROR',
          message: 'Fetch failed',
          cause: err as Error,
        })
      }
    }
  }

  if (process.client) {
    fetchCustomer().catch((error) => {
      if (error instanceof CustomerError) {
        $sentry.captureException(error, {
          tags: {
            level: SeverityLevel.ERROR,
            handling: HandlingLevel.CAUGHT_ONLY,
          },
        })
      }
    })
  } else {
    // "Resolve" the customer id during server side rendering to prevent blocking.
    customerRefs.id.value = ''
  }

  return customerRefs
}

export default createSharedComposable(customer) as () => UseCustomer
