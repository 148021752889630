<template>
  <footer class="TheFooter" role="contentinfo" aria-label="Footer">
    <nav
      v-if="!hideSections"
      class="TheFooter_Sections"
      aria-label="Footer Sections"
      data-test-id="nav-sections"
    >
      <div class="TheFooter_Sections_Column">
        <h2 class="TheFooter_Sections_Column_Title">Grove</h2>
        <ul class="TheFooter_Sections_Column_List">
          <li v-for="link in FOOTER_NAVIGATION.grove" :key="link.text">
            <SkyLink :to="link.to" data-test-id="values-link">
              {{ link.text }}
            </SkyLink>
          </li>
          <li>
            <SkyLink
              unstyled
              :to="bcorpLink"
              class="TheFooter_Image"
              target="_blank"
              rel="noopener"
            >
              <SkyImage
                src="https://images.grove.co/upload/v1554326506/global/Icons/svg-icons/b-corp.svg"
                alt="Certified B Corporation"
              />
            </SkyLink>
          </li>
        </ul>
        <ClientOnly>
          <SkyLink
            v-if="appBadge && !isMediumUp"
            data-test-id="download-app-link"
            to="https://grove.onelink.me/rUOP/8aba8efd"
            unstyled
          >
            <SkyImage
              :src="appBadge"
              alt="Download the Grove App"
              class="TheFooter_Image TheFooter_Image--app"
            />
          </SkyLink>
        </ClientOnly>
      </div>
      <div class="TheFooter_Sections_Column">
        <h2 class="TheFooter_Sections_Column_Title">Support</h2>
        <ul class="TheFooter_Sections_Column_List">
          <li v-for="link in FOOTER_NAVIGATION.support" :key="link.text">
            <SkyLink
              data-test-id="about-link"
              :rel="link.rel"
              :target="link.target"
              :to="link.to"
            >
              {{ link.text }}
            </SkyLink>
          </li>
        </ul>
      </div>
      <div class="TheFooter_Sections_Column">
        <h2 class="TheFooter_Sections_Column_Title">Shop</h2>
        <ul class="TheFooter_Sections_Column_List">
          <li
            v-for="link in FOOTER_NAVIGATION.shop"
            :key="link.text"
            class="TheFooter_Sections_Column_List_Item"
          >
            <SkyLink :to="link.to" data-test-id="shop-link">
              {{ link.text }}
            </SkyLink>
          </li>
        </ul>
      </div>
      <div class="TheFooter_Sections_Column">
        <h2 class="TheFooter_Sections_Column_Title">Follow Grove</h2>
        <ul class="TheFooter_Sections_Column_List">
          <li v-for="link in FOOTER_NAVIGATION.social" :key="link.text">
            <SkyLink
              :to="link.to"
              class="TheFooter_ImageLink"
              data-test-id="social-link"
              target="_blank"
            >
              <SkyImage :src="link.image" :alt="`${link.text} logo`" />
              {{ link.text }}
            </SkyLink>
          </li>
        </ul>
      </div>
    </nav>

    <nav
      class="TheFooter_Policy"
      aria-label="Policy footer"
      data-test-id="nav-policy"
    >
      <section class="TheFooter_Logo">
        <SkyImage
          :src="LOGOS.wordmark.registered"
          alt="Grove Collaborative logo"
        />
      </section>
      <section class="TheFooter_Policy_Section">
        <div class="TheFooter_Policy_Link" data-test-id="the-footer-copyright">
          &copy;{{ new Date().getFullYear() }} Grove Collaborative, Inc.
        </div>
        <SkyLink
          v-for="link in FOOTER_NAVIGATION.policy"
          :key="link.text"
          :to="link.to"
          data-test-id="policy-link"
          rel="noopener"
          target="_blank"
        >
          {{ link.text }}
        </SkyLink>
      </section>
      <section
        class="TheFooter_Privacy"
        aria-label="California consumer notice"
      >
        Notice to California Consumers: For your opt-out options, see:
        <SkyLink
          inline
          to="https://preferences.grove.co/dont_sell"
          target="_blank"
          data-test-id="do-not-sell-link"
        >
          Do Not Sell My Personal Information
        </SkyLink>
      </section>
      <section class="TheFooter_Logo">
        <SkyImage :src="LOGOS.wordmark.cairn" alt="Cairn logo" />
      </section>
    </nav>
  </footer>
</template>
<script setup lang="ts">
import { FOOTER_NAVIGATION } from '~/constants/footerNavigation'
import { LOGOS } from '~/constants/logos'

defineProps({
  hideSections: {
    type: Boolean,
    default: false,
  },
})

const { appBadge } = useMobile()
const { isMediumUp } = useSkyBreakpoint()

const bcorpLink =
  'https://www.bcorporation.net/en-us/find-a-b-corp/company/grove-collaborative'
</script>
<style lang="scss">
.TheFooter {
  @include type-body('m', true);

  --container-max: 1000px;
  --text-color-primary: var(--text-color-secondary-ondark);
  --text-color-action-primary: var(--text-color-secondary-ondark);
  --text-color-action-primary-hover: var(--text-color-action-hover-ondark);

  background-color: var(--surface-color-dark);
  width: 100%;

  &_Sections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    padding: var(--spacing-10x) var(--spacing-4x);
    max-width: var(--container-max);
    gap: var(--spacing-8x) var(--spacing-4x);

    @include for-medium-up {
      grid-template-columns: repeat(4, 1fr);
    }

    &_Column {
      &_Title {
        font-weight: inherit;
        font-family: inherit;
        font-size: inherit;
        color: var(--text-color-action-hover-ondark);
        margin-bottom: var(--spacing-6x);
      }

      &_List {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: var(--spacing-4x);
      }
    }
  }

  &_ImageLink {
    display: flex;
    justify-content: center;
    max-width: fit-content;
    padding-bottom: var(--spacing-2x);
    gap: var(--spacing-4x);
  }

  &_Image {
    display: block;

    &--app {
      max-width: 150px;
    }
  }

  &_Policy {
    background-color: var(--surface-color-darker);
    padding: 0 var(--spacing-3x) var(--spacing-6x);

    &_Section {
      --container-max: 1400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: var(--spacing-4x);
      margin: 0 auto;
      max-width: var(--container-max);

      @include for-medium-up {
        flex-direction: row;
      }

      .TheFooter_Policy_Link,
      .SkyLink {
        line-height: var(--line-height-tight);
      }
    }
  }

  &_Privacy {
    font-size: var(--font-size-75);
    text-align: center;
    margin: var(--spacing-4x) 0 0;
  }

  &_Logo {
    display: flex;
    justify-content: center;
    padding: var(--spacing-8x) 0;
  }
}
</style>
