export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/x-icon","href":"https://images.grove.co/upload/v1612546650/global/favicon/grove/favicon.ico"},{"rel":"icon","type":"image/svg+xml","href":"https://images.grove.co/upload/v1612546650/global/favicon/grove/favicon-svg.svg"},{"rel":"apple-touch-icon","href":"https://images.grove.co/upload/v1612546650/global/favicon/grove/favicon-180.png"},{"rel":"apple-touch-icon","sizes":"192x192","href":"https://images.grove.co/upload/v1605539210/global/favicon/grove/favicon-192.png"},{"rel":"apple-touch-icon","sizes":"512x512","href":"https://images.grove.co/upload/v1605539210/global/favicon/grove/favicon-512.png"}],"style":[],"script":[{"src":"https://cdn.optimizely.com/js/312229649.js"},{"src":"https://cnstrc.com/js/cust/grove_NhP1OG.js","async":true}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false