import { EffectScope, effectScope, getCurrentScope, onScopeDispose } from 'vue'

import type { Composition } from '~/types/composable'

const resetMap = new WeakMap()

type SharedComposableOptions = {
  allowDisposal: boolean
  delayDisposal: number
}

type TComposable<TComposition> = (...args: any[]) => TComposition

/**
 * Resets the state and scope of a shared composition. Only for unit tests.
 *
 */
export const resetComposable = (composable: Function) => {
  resetMap.set(composable(), true)
}

/**
 * Share a composable's listeners and refs across multiple components.
 * The composable is destroyed when nothing is using it anymore.
 *
 */
export const createSharedComposable = <TComposition extends Composition>(
  composable: TComposable<TComposition>,
  options: SharedComposableOptions = {
    allowDisposal: true,
    delayDisposal: 30 * 1000,
  }
): TComposable<TComposition> => {
  let disposeTimeout: ReturnType<typeof setTimeout> | undefined
  let scope: EffectScope | undefined
  let state: TComposition | undefined
  let subscribers = 0

  /**
   * Stop the effect scope and reset state.
   *
   */
  const disposeComposable = () => {
    scope?.stop()
    disposeTimeout = state = scope = undefined
    subscribers = 0
  }

  /**
   * Invoked when the current effect scope is stopped.
   * If there are no other subscribers stop the effect scope and reset state if allowed.
   */
  const scopeDisposed = () => {
    if (scope && --subscribers <= 0) {
      if (options.delayDisposal > 0) {
        if (disposeTimeout) clearTimeout(disposeTimeout)
        disposeTimeout = setTimeout(disposeComposable, options.delayDisposal)
      } else {
        disposeComposable()
      }
    }
  }

  return (...args) => {
    if (disposeTimeout) {
      clearTimeout(disposeTimeout)
      disposeTimeout = undefined
    }

    if (state && resetMap.has(state)) {
      resetMap.delete(state)
      disposeComposable()
    }

    if (state === undefined) {
      scope = effectScope(true)
      state = scope.run(() => composable(...args))
    }

    if (typeof state !== 'object') {
      throw new TypeError('Composable must return an object')
    }

    if (options.allowDisposal) {
      subscribers++

      if (getCurrentScope()) {
        onScopeDispose(scopeDisposed)
      }
    }

    return state
  }
}
