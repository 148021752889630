import revive_payload_client_4sVQNw7RlN from "/home/runner/work/grove-web/grove-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/grove-web/grove-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/grove-web/grove-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/runner/work/grove-web/grove-web/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/runner/work/grove-web/grove-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/grove-web/grove-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/grove-web/grove-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/grove-web/grove-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/runner/work/grove-web/grove-web/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/grove-web/grove-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import events_client_4tGYDAYClX from "/home/runner/work/grove-web/grove-web/plugins/events.client.ts";
import info_on_app_load_client_T32Y0JYcmE from "/home/runner/work/grove-web/grove-web/plugins/info-on-app-load.client.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/grove-web/grove-web/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  chunk_reload_client_UciE0i6zes,
  events_client_4tGYDAYClX,
  info_on_app_load_client_T32Y0JYcmE,
  sentry_client_shVUlIjFLk
]