import createEmitter, { events, helpers } from '@groveco/http-events'

/**
 * This custom plugin provides the emitter, events, and context generator to
 * track data via http-events.
 */
export default defineNuxtPlugin({
  name: 'events',
  setup() {
    const runtimeConfig = useRuntimeConfig()
    const config = runtimeConfig.public.events

    // Note: we do not create a plugin logger since http-events comes with its
    // own logging.

    const emitter = createEmitter({
      /**
       * Enable the `debug` flag in non-product environments to report on
       * emitted and handled events in the browser console.
       */
      debug: config.debug,
      integrations: config.enabled ? {} : false,
    })

    const plugin = {
      emit: emitter.emit,
      ...events,
      contexts: helpers.contexts,
    }

    return {
      provide: {
        events: plugin,
      },
    }
  },
})
